<template>
    <zw-sidebar @shown="shown"
                :title="title"
                :disable-wrapper="!new_design"
                :showClose="!(preview && !hidePreview)"
    >
        <template v-slot:sidebar-title>
            <b-row style="width: 100%">
                <b-col cols="10" style="font-size: 14px">
                    <template v-if="form.offering">
                        <template v-if="formType=='return'">{{ $t('offering.title.offering_return') }}:</template>
                        <template v-else>{{ $t('offering.title.offering_nr') }}:</template>
                        {{ form.offering.number }}
                        <font-awesome-icon class="ml-2 mr-2" icon="caret-right"/>
                        {{ form.offering.project_production.name }}
                        <font-awesome-icon class="ml-2 mr-2" icon="caret-right"/>
                        {{ form.offering.name }}
                        <template v-if="formType!='supply'">
                            <span class="ml-2 mr-2">|</span>
                            <a @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:form.offering.contact_person.kunden_Id})"
                               style="color: #321fdb"
                            >
                                {{
                                    (form.offering.billing_address.company || form.offering.billing_address.firstname + ' ' +
                                        form.offering.billing_address.lastname)
                                }}
                                <font-awesome-icon class="ml-1" icon="external-link-alt"/>
                            </a>

                            <b-button @click="copyOfferingUrl(form.offering.id)"
                                      size="sm"
                                      variant="info"
                                      class="ml-2"
                                      v-if="form.offering.id"
                            >
                                <font-awesome-icon icon="copy"/>
                            </b-button>
                        </template>
                    </template>
                </b-col>
            </b-row>
        </template>
        <template v-if="new_design && id">
            <b-row>
                <b-col :cols="(preview && !hidePreview) ? 7 : 12">
                    <b-card no-body>
                        <b-tabs v-if="form.offering" lazy card ref="offering_tabs"
                                @input="(val)=>{tabChanged(val)}">
                            <b-tab v-for="(icon,key) in tabs"
                                   :key="key"
                                   :data-key="key"
                                   class="pt-2"
                                   v-if="showTab(key)"
                            >
                                <template #title>
                                    <font-awesome-icon :icon="icon"/>
                                    {{ $t('offering.tab.' + key) }}
                                </template>
                                <offering-general v-if="key=='general'"
                                                  v-model="form"
                                                  :shown="shown"
                                                  :form-type="formType"
                                                  :readonly="readonly"
                                                  :partialReadonly="partialReadonly"
                                                  :show-preview="showPreview"
                                />
                                <offering-address v-else-if="key=='address'"
                                                  :shipping-address="form.shipping_address"
                                                  :billing-address="form.billing_address"
                                                  :vat-check-settings="form.vat_check_settings"
                                                  :vat-request="form.offering.vat_request ? form.offering.vat_request : {}"
                                                  :offering-id="form.offering.id"
                                                  :provider-id="form.customer_provider ? form.customer_provider.id : null"
                                                  :after-update="shown"
                                />
                                <offering-documents v-else-if="key=='documents'"
                                                    :form="form"
                                                    :after-update="shown"
                                />
                                <offering-payment-info v-else-if="key=='payment_info'"
                                                       :offering="form.offering"
                                                       :offering_id="form.offering.id"
                                                       :payment_method="form.offering.payment_method"
                                                       :payment_status="form.offering.payment_status"
                                                       :return_count="form.return_count"
                                                       :return_status="form.offering.return_status"
                                                       :payment_date="form.offering.payment_date"
                                />
                                <offering-shipping-info v-else-if="key=='shipping_info'"
                                                        :form="form"
                                                        :after-update="shown"
                                />
                                <offering-fulfilment-info v-else-if="key=='fulfilment_info'"
                                                          :form="form"
                                                          :readonly="readonly"
                                                          :partialReadonly="partialReadonly"
                                                          :after-update="shown"
                                />
                                <offering-header-footer v-else-if="key=='header_footer'"
                                                        :offering_id="form.offering.id"
                                                        :last-type="lastType ? lastType : 'rechnung'"
                                                        :preview="!!+preview"
                                                        :after-update="shown"
                                                        :form-type="formType"
                                />
                                <offering-events v-else-if="key=='events'"
                                                 :offering_id="form.offering.id"
                                />
                                <offering-history v-else-if="key=='history'"
                                                  :offering_id="form.offering.id"
                                />
                                <offering-info v-else-if="key=='info'"
                                               :form="form"
                                />
                                <!--                                <offering-attachments v-else-if="key=='attachments'"
                                                                                      :offering_id="form.offering.id"
                                                                />-->
                                <!--                                <offering-api-log v-else-if="key=='api_logs'"-->
                                <!--                                                  :offering_id="form.offering.id"-->
                                <!--                                />-->
                                <!--                                <contracts v-else-if="key=='contracts'"
                                                                           :customerId="form.offering.contact_person.kunden_Id"
                                                                           :offeringId="form.offering.id"
                                                                />-->
                                <!--                                <offering-jobs v-else-if="key=='jobs'"-->
                                <!--                                               :offeringId="form.offering.id"-->
                                <!--                                />-->
                                <offering-suppliers v-else-if="key=='suppliers'"
                                                    :offeringId="form.offering.id"
                                                    :main-supplier="form.offering.main_supplier"
                                                    :shown="shown"
                                />
                                <customer-mails v-else-if="key=='emails'"
                                                :customerId="form.offering.contact_person.kunden_Id"
                                                :offeringId="form.offering.id"
                                />
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>
                <b-col v-if="(preview && !hidePreview)" cols="5">
                    <b-row>
                        <b-col class="text-right">
                            <b-button
                                @click="closePreview()"
                                class="mb-2"
                            >
                                <font-awesome-icon icon="times"/>
                            </b-button>
                        </b-col>
                    </b-row>

                    <iframe width="100%"
                            id="myFrame"
                            height="100%"
                            style="border:none"
                            :src="source"
                    ></iframe>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <iframe
                ref="kva-iframe"
                width="100%"
                height="100%"
                style="border:none"
                :src="url"
            ></iframe>
        </template>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import CustomerMails from "@/tables/CustomerMails"
import OfferingHistory from "@/modals/offering/offering-blocks/OfferingHistory"
import OfferingPaymentInfo from "@/modals/offering/offering-blocks/OfferingPaymentInfo"
import OfferingShippingInfo from "@/modals/offering/offering-blocks/OfferingShippingInfo"
import OfferingFulfilmentInfo from "@/modals/offering/offering-blocks/OfferingFulfilmentInfo"
import OfferingInfo from "@/modals/offering/offering-blocks/OfferingInfo"
import OfferingAddress from "@/modals/offering/offering-blocks/OfferingAddress";
import OfferingDocuments from "@/modals/offering/offering-blocks/OfferingDocuments";
import OfferingGeneral from "@/modals/offering/offering-blocks/OfferingGeneral";
import OfferingHeaderFooter from "@/modals/offering/offering-blocks/OfferingHeaderFooter";
import OfferingEvents from "@/modals/offering/offering-blocks/OfferingEvents";
import OfferingSuppliers from "@/modals/offering/offering-blocks/OfferingSuppliers";


export default {
    name: 'OfferingModal',
    components: {
        OfferingSuppliers,
        OfferingEvents,
        OfferingHeaderFooter,
        OfferingGeneral,
        OfferingDocuments,
        OfferingAddress,
        OfferingHistory,
        OfferingPaymentInfo,
        OfferingShippingInfo,
        OfferingFulfilmentInfo,
        OfferingInfo,
        CustomerMails,
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            new_design: +localStorage.getItem('new_design') || true,
            payload: null,
            id: null,
            formType: null,
            readonly: false,
            partialReadonly: false,
            callback: null,
            defaultForm: {},
            form: {},
            title: this.$t('offering.title.offering'),
            url: null,
            preview: false,
            default_preview_type: 'rechnung',
            lastType: null,
            cleanLastType: false,
            source: null,
            hidePreviewTabs: ['documents', 'payment_info', 'shipping_info', 'fulfilment_info', 'history', 'info', 'attachments', 'emails'],
            hidePreview: false,
            tabs: {
                'general': 'home',
                'address': 'address-card',
                'documents': 'file-pdf',
                'payment_info': 'credit-card',
                'shipping_info': 'map-marked-alt',
                'fulfilment_info': 'dolly',
                'header_footer': 'file-invoice',
                'events': 'calendar',
                'history': 'clock',
                'info': 'info',
                'suppliers': 'users',
                'emails': 'envelope',
            },
        }
    },
    methods: {
        ...mapGetters('Offering', ['getOffering']),
        ...mapGetters('Invoice', ['getPreview']),
        ...mapGetters('CommonData', ['getSettings']),
        modalTitle() {
            return this.form.offering.number
        },
        copyOfferingUrl(id) {
            this.$root.copyToClipboard(window.location.origin + window.location.pathname + '/#/sales?offering=' + id)
        },
        showTab(key) {
            const kvaMode = ['general', 'address', 'documents', 'payment_info', 'shipping_info', 'fulfilment_info', 'header_footer', 'events', 'info', 'emails'];
            const simpleMode = ['general', 'address', 'documents', 'payment_info', 'shipping_info', 'fulfilment_info', 'header_footer', 'info'];
            const returnType = ['general', 'address', 'documents', 'shipping_info', 'fulfilment_info'];
            const templateType = ['general', 'address', 'header_footer', 'info'];
            const supplyType = ['general', 'documents', 'suppliers', 'header_footer', 'info'];

            if (this.form.offering.type == 'return') {
                return returnType.includes(key)
            } else if (this.form.offering.type == 'template') {
                return templateType.includes(key)
            } else if (this.form.offering.type == 'supply') {
                return supplyType.includes(key)
            }

            if (this.$root.simpleMode) {
                return simpleMode.includes(key)
            }

            return kvaMode.includes(key);
        },
        tabChanged(index) {
            this.$root.$children[0].updateFormValues('offering-modal')
            let activeTab = this.$refs.offering_tabs.tabs[index]
            let tabKey = activeTab.$el.getAttribute('data-key')
            this.hidePreview = this.hidePreviewTabs.includes(tabKey);
        },
        shown(newType = null) {
            if (this.cleanLastType) {
                this.lastType = null
                this.cleanLastType = false
            }

            if (newType) {
                this.lastType = newType
            }
            if (typeof this.payload === 'object') {
                this.id = this.payload.id
                this.$set(this, 'formType', this.payload.formType)
                if (this.formType == 'return') {
                    this.$set(this, 'readonly', true)
                    this.$set(this, 'partialReadonly', true)
                }
            } else {
                this.id = this.payload
            }

            this.loading = false
            if (!this.new_design) {
                this.url = '/project/kva/index/id/' + this.id
            } else {
                const offeringFetch = this.$store.dispatch('Offering/fetchOfferingWithoutLoading', this.id)

                Promise.all([offeringFetch])
                    .then(() => {
                        this.form = {
                            ...JSON.parse(JSON.stringify(this.defaultForm)),
                            ...JSON.parse(JSON.stringify(this.getOffering()))
                        }

                        if (this.getSettings()['default_preview_enable'] == 1) {
                            this.preview = 1;
                            this.default_preview_type = this.getSettings()['default_preview_type']
                        }

                        if (this.getSettings()['allow_order_edit'] !== '1') {
                            if (this.form.offering.status && this.getSettings()['kva_readonly_status'].includes(this.form.offering.status)) {
                                this.$set(this, 'readonly', true)
                            } else {
                                if (this.getOffering().invoices.length > 0) {
                                    this.$set(this, 'readonly', true)
                                    this.$set(this, 'partialReadonly', true)
                                }
                            }
                        }
                        if (this.preview) {
                            if (this.lastType) {
                                this.showPreview(this.id, this.lastType)
                            } else {
                                this.showPreview(this.id, this.default_preview_type)
                            }
                        }

                        if (this.form.vat_check_settings.vat_auto_check == 1) {
                            const checkVat = this.$store.dispatch('Offering/checkOfferingVatId', this.id)
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        changeNewDesign(val) {
            localStorage.setItem('new_design', val)
        },
        showPreview(id, type = null, show = false) {
            if (type == null) {
                type = this.lastType
            }
            this.lastType = type
            if (type == null) {
                type = 'rechnung'
            }
            if (type) {
                this.$store.dispatch('Invoice/fetchInvoicePreview', {
                    id: id,
                    type: type
                })
                    .then(() => {
                        this.source = 'data:application/pdf;base64,' + this.getPreview().content

                        if (show) {
                            this.preview = true
                        }
                    })
            }
        },
        closePreview() {
            this.preview = false
            this.cleanLastType = true
            this.source = null
        }
    },
    watch: {
        new_design: function (val) {
            this.shown()
        },
    },
}
</script>