<template>
    <draggable :class="'dragArea'+(root ?' first':'')"
               tag="ul"
               :list="items"
               :group="{ name: 'g1' }"
               handle=".handle"
               @end="dragged"
               :disabled="readOnly"
    >
        <template v-for="(el,item_index) in items">
            <li :key="'product_'+item_index">
                <table :class="el.type=='category' ? 'category-table zoomed-table' : 'zoomed-table'"
                       style="border-bottom: solid 1px lightgray;"
                >
                    <tr>
                        <td style="min-width: 30px;max-width:30px;text-align: center">
                            <b-form-checkbox v-if="el.type=='category'"
                                             :key="el.data.id"
                                             :checked="el.data.id == selectedCategory"
                                             @change="(val)=>{categorySelected(val ? el.data.id : null)}"
                            ></b-form-checkbox>
                        </td>
                        <td :style="'min-width: '+(50-(el.level*15))+'px;max-width: '+(50-(el.level*15))+'px;'">
                            <div class="handle" :style="'float:left'">
                                <font-awesome-icon icon="grip-horizontal"></font-awesome-icon>
                            </div>
                        </td>
                        <td style="min-width: 30px;max-width:30px;text-align: center">
                            {{ el.number }}
                        </td>
                        <template v-if="el.type=='category'">
                            <td class="position-column"
                                v-for="(field,index) in fields"
                                :key="field.key"
                                :style="columnsWidth[field.key] ? ('min-width:'+columnsWidth[field.key]+';max-width:'+columnsWidth[field.key]) : ''"
                            >
                                <template v-if="index==0">
                                    <zw-input-group v-if="el.data.edit"
                                                    name="name"
                                                    v-model="el.data.category_alias"
                                                    disable-label
                                                    @change="saveCategory(el)"
                                    ></zw-input-group>
                                    <div v-else>{{ el.data.category_alias }}</div>
                                </template>
                                <template v-if="field.key=='actions'">
                                    <b-dropdown class="mb-1"
                                                left
                                                variant="primary"
                                                size="sm"
                                    >
                                        <template #button-content>
                                            {{ $t('common.label.actions') }}
                                        </template>

                                        <template v-if="!readOnly">
                                            <b-dropdown-item v-if="!el.data.edit"
                                                             @click="editCategory(el)">
                                                <font-awesome-icon icon="edit" class="mr-2"></font-awesome-icon>
                                                <font-awesome-icon class="mr-2" icon="edit"/>
                                                {{ $t('common.title.edit') }}
                                            </b-dropdown-item>
                                            <template v-else>
                                                <b-dropdown-item @click="saveCategory(el)">
                                                    <font-awesome-icon icon="save" class="mr-2"></font-awesome-icon>
                                                    {{ $t('common.title.save') }}
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="cancelEditCategory(el)">
                                                    <font-awesome-icon icon="ban" class="mr-2"></font-awesome-icon>
                                                    {{ $t('common.title.cancel') }}
                                                </b-dropdown-item>
                                            </template>
                                        </template>

                                        <b-dropdown-item v-if="!readOnly" @click="deleteCategory(el.data.id)">
                                            <font-awesome-icon icon="trash" class="mr-2"></font-awesome-icon>
                                            {{ $t('common.title.delete') }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </td>
                        </template>
                        <template v-else>
                            <td class="position-column"
                                v-for="field in fields"
                                :key="field.key"
                                :style="columnsWidth[field.key] ? ('min-width:'+columnsWidth[field.key]+';max-width:'+columnsWidth[field.key]) : ''"
                            >
                                <component v-if="columnExtensions[field.key]"
                                           v-bind:is="columnExtensions[field.key]"
                                           v-model="el.data"
                                           :save-position="savePosition"
                                           :read-only="readOnly"
                                           :partialReadonly="partialReadonly"
                                ></component>
                                <div v-else-if="field.key=='article_barcode'">
                                    {{ el.data.article_barcode }}
                                </div>
                                <div v-else-if="field.key.startsWith('_custom_')">
                                    <custom-column v-model="el.data.customValues || {}"
                                                   :position="el.data"
                                                   :save-position="savePosition"
                                                   :read-only="readOnly"
                                                   :field="el.data.customFields[field.key.slice(8)]"
                                    ></custom-column>
                                </div>
                                <div v-else-if="field.key=='actions'">
                                    <b-dropdown class="mb-1"
                                                left
                                                variant="primary"
                                                size="sm"
                                    >
                                        <template #button-content>
                                            {{ $t('common.label.actions') }}
                                        </template>

                                        <template v-if="formType=='return'">
                                            <b-dropdown-item v-if="el.data.article_id"
                                                             @click="$root.$children[0].openModal('stock-return-modal', {article_id:el.data.article_id,offering_id:el.data.offering_id},refreshTable, {width: '800px'})">
                                                <font-awesome-icon icon="dumpster" class="mr-2"></font-awesome-icon>
                                                {{ $t('common.title.stock') }}
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click="$root.$children[0].openModal('return-position-modal', {id:el.data.id,count:el.data.return_count},refreshTable, {width: '800px'})">
                                                <font-awesome-icon icon="hand-point-left"
                                                                   class="mr-2"></font-awesome-icon>
                                                {{ $t('common.title.return') }}
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="el.data.article_id"
                                                             @click="getBarCode(el.data.article_id)">
                                                <font-awesome-icon icon="barcode" class="mr-2"></font-awesome-icon>
                                                {{ $t('common.title.barcode') }}
                                            </b-dropdown-item>
                                        </template>

                                        <b-dropdown-item v-if="!readOnly"
                                                         @click="$root.$children[0].openModal('offering-position-modal', {id:el.data.id},refreshTable)">
                                            <font-awesome-icon icon="edit" class="mr-2"></font-awesome-icon>
                                            {{ $t('common.title.edit') }}
                                        </b-dropdown-item>
                                        <b-dropdown-item v-if="!readOnly"
                                                         @click="duplicatePosition(el.data)">
                                            <font-awesome-icon icon="copy" class="mr-2"></font-awesome-icon>
                                            {{ $t('common.title.copy') }}
                                        </b-dropdown-item>
                                        <b-dropdown-item v-if="!readOnly"
                                                         @click="deletePosition(el.data)">
                                            <font-awesome-icon icon="trash" class="mr-2"></font-awesome-icon>
                                            {{ $t('common.title.delete') }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <template v-else>{{ el.data[field.key] }}</template>
                            </td>
                        </template>
                    </tr>
                </table>
                <nested-position v-if="el.type=='category'"
                                 :selected-category="selectedCategory"
                                 :items="el.items"
                                 :fields="fields"
                                 :refresh-table="refreshTable"
                                 :render-table="renderTable"
                                 :columns-width="columnsWidth"
                                 :read-only="readOnly"
                                 :partialReadonly="partialReadonly"
                                 :form-type="formType"
                                 @dragged="dragged"
                                 @category-selected="categorySelected"
                />
            </li>
        </template>
    </draggable>
</template>

<script>
import Draggable from "vuedraggable";
import commonSave from "@/mixins/common-save";
import CustomColumn from "@/modals/offering/offering-blocks/positions/customColumn";

export default {
    name: 'NestedPosition',
    components: {CustomColumn, Draggable},
    mixins: [commonSave],
    props: {
        selectedCategory: {
            type: Number
        },
        items: {
            type: Array
        },
        fields: {
            type: Array
        },
        refreshTable: {
            type: Function
        },
        readOnly: {
            type: Boolean
        },
        partialReadonly: {
            type: Boolean
        },
        formType: [String],
        renderTable: {
            type: Function
        },
        columnsWidth: {
            type: Object
        },
        root: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            columnExtensions: {
                'count': () => import('./countColumn'),
                'return_count': () => import('./returnCountColumn'),
                'return_price': () => import('./returnPriceColumn'),
                'price': () => import('./priceColumn'),
                'name': () => import('./nameColumn'),
                'vat': () => import('./vatColumn'),
                'sku': () => import('./skuColumn'),
                'unit': () => import('./unitColumn'),
                'weight_unit': () => import('./weightUnitColumn'),
                'weight': () => import('./weightColumn'),
                'media': () => import('./mediaColumn'),
                'customized_product_data': () => import('./customizedProductDataColumn'),
                'article_stock_id': () => import('./articleStockIdColumn'),
                'shipping': () => import('./shippingColumn'),
                'description': () => import('./descriptionColumn'),
                'supplier_price': () => import('./supplierPriceColumn'),
            }
        }
    },
    methods: {
        duplicatePosition(position) {
            this.$store.dispatch('Positions/duplicatePosition', position.id)
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                    this.refreshTable()
                })
        },
        deletePosition(position) {
            this.$removeConfirm('Offering/deletePosition', position, this.refreshTable)
        },
        deleteCategory(id) {
            this.$removeConfirm('Positions/deleteCategory', id, this.refreshTable)
        },
        getBarCode(id) {
            this.$store.dispatch('Barcode/getBarCode', {id, type: 'article'})
                .then(response => {
                    let w = window.open("");
                    const image = "data:image/jpg;base64," + response.data.content
                    w.document.write('<img src="' + image + '"/>');
                    setTimeout(() => {
                        w.print();
                    }, 500)
                })
        },
        savePosition(field, position, value = null) {
            let data = {
                offeringId: position.offering_id,
                id: position.id,
            }
            if (value) {
                data[field] = value
            } else {
                data[field] = position[field]
            }

            this.$store.dispatch('Positions/saveOfferingPosition', data)
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                    this.renderTable()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        editCategory(el) {
            this.$set(el.data, 'edit', true)
            this.$set(el.data, 'original', el.data.category_alias)
        },
        cancelEditCategory(el) {
            this.$set(el.data, 'category_alias', el.data.original)
            this.$set(el.data, 'edit', false)
        },
        saveCategory(el) {
            this.$set(el.data, 'edit', false)
            this.$store.dispatch('Positions/saveCategory', {
                id: el.data.id,
                category_alias: el.data.category_alias,
            })
                .then((response) => {
                })
                .finally(() => {
                    this.loading = false
                    this.refreshTable()
                })
        },
        categorySelected(val) {
            this.$emit('category-selected', val ? val : null)
        },
        dragged() {
            this.$emit('dragged', true)
        },
    }
}
</script>

<style>
.category-table {
    background-color: #f6f6f6;
}

.dragArea {
    list-style: none;
    padding-inline-start: 0px;
    border: solid 1px gray;
    border-right: none;
    border-top: none;
    margin-left: 11px;
    min-height: 15px;
    margin-bottom: 0px;
    border-bottom: 1px solid lightgray;
}

.dragArea.first {
    margin-left: 0px;
}

.position-column {
    min-width: 120px;
    max-width: 120px;
    padding: 0.55rem;
}

.position-column .form-group {
    margin-bottom: 0;
}
</style>