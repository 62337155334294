<template>
    <div>
        <b-table-simple v-if="!loading" striped>
            <b-thead head-variant="info">
                <b-th></b-th>
                <b-th>
                    {{ $t('offering.address.billing_address') }}
                    <b-dropdown class="mb-1 ml-2"
                                left
                                variant="primary"
                                size="sm"
                    >
                        <template #button-content>
                            {{ $t('common.label.actions') }}
                        </template>
                        <b-dropdown-item
                            @click="$root.$children[0].openModal('offering-address-modal',
                          {type:'billing',offeringId:offeringId},afterUpdate,{width: '800px'}
                          )"
                        >
                            <font-awesome-icon class="mr-2 text-primary" icon="edit"></font-awesome-icon>
                            {{ $t('common.button.edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="syncAddress('billing',offeringId)"
                        >
                            <font-awesome-icon class="mr-2 text-info" icon="sync"></font-awesome-icon>
                            {{ $t('common.title.sync_from_customer') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="providerId"
                            @click="syncProviderAddress('billing',offeringId)"
                        >
                            <font-awesome-icon class="mr-2 text-info" icon="sync"></font-awesome-icon>
                            {{ $t('common.title.sync_from_provider') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="copyAddress('billing',offeringId)"
                        >
                            <font-awesome-icon class="mr-2 text-warning" icon="arrow-left"></font-awesome-icon>
                            {{ $t('common.title.copy_from_shipping') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="vatCheckSettings.vat_check == 1"
                            @click="checkVat"
                        >
                            <font-awesome-icon v-if="vatRequest.status == 200" class="mr-2 text-success"
                                               icon="check-circle"/>
                            <font-awesome-icon v-else class="mr-2 text-danger" icon="times-circle"/>
                            Check VAT
                        </b-dropdown-item>
                    </b-dropdown>
                </b-th>
                <b-th>
                    {{ $t('offering.address.shipping_address') }}
                    <b-dropdown class="mb-1 ml-2"
                                left
                                variant="primary"
                                size="sm"
                    >
                        <template #button-content>
                            {{ $t('common.label.actions') }}
                        </template>
                        <b-dropdown-item
                            @click="$root.$children[0].openModal('offering-address-modal',
                          {type:'shipping',offeringId:offeringId},afterUpdate,{width: '800px'}
                          )"
                        >
                            <font-awesome-icon class="mr-2 text-primary" icon="edit"></font-awesome-icon>
                            {{ $t('common.button.edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="syncAddress('shipping',offeringId)"
                        >
                            <font-awesome-icon class="mr-2 text-info" icon="sync"></font-awesome-icon>
                            {{ $t('common.title.sync_from_customer') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="providerId"
                            @click="syncProviderAddress('shipping',offeringId)"
                        >
                            <font-awesome-icon class="mr-2 text-info" icon="sync"></font-awesome-icon>
                            {{ $t('common.title.sync_from_provider') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="copyAddress('shipping',offeringId)"
                        >
                            <font-awesome-icon class="mr-2 text-warning" icon="arrow-left"></font-awesome-icon>
                            {{ $t('common.title.copy_from_billing') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-th>
            </b-thead>
            <tbody>
            <b-tr>
                <b-td>{{ $t('offering.address.company') }}</b-td>
                <b-td>{{ billingAddress.company }}</b-td>
                <b-td>{{ shippingAddress.company }}</b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.full_name') }}</b-td>
                <b-td>{{ billingAddress.firstname }} {{ billingAddress.middlename }} {{
                        billingAddress.lastname
                    }}
                </b-td>
                <b-td>{{ shippingAddress.firstname }} {{ shippingAddress.middlename }} {{
                        shippingAddress.lastname
                    }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.care_of_address') }}</b-td>
                <b-td>{{ billingAddress.care_of_address }}
                </b-td>
                <b-td>{{ shippingAddress.care_of_address }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.street') }}</b-td>
                <b-td>{{ billingAddress.street }}</b-td>
                <b-td>{{ shippingAddress.street }}</b-td>
            </b-tr>
            <b-tr v-if="shippingAddress.packstation_nr" style="background-color: lightyellow">
                <b-td>{{ $t('offering.address.packstation_nr') }}</b-td>
                <b-td>&nbsp;</b-td>
                <b-td>{{ shippingAddress.packstation_nr }}</b-td>
            </b-tr>
            <b-tr v-if="shippingAddress.postnumber" style="background-color: lightyellow">
                <b-td>{{ $t('offering.address.postnumber') }}</b-td>
                <b-td>&nbsp;</b-td>
                <b-td>{{ shippingAddress.postnumber }}</b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.post_code_and_city') }}</b-td>
                <b-td>{{ billingAddress.postCode }} {{ billingAddress.city }}</b-td>
                <b-td>{{ shippingAddress.postCode }} {{ shippingAddress.city }}</b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.country') }}</b-td>
                <b-td>{{ billingAddress.country }}</b-td>
                <b-td>{{ shippingAddress.country }}</b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.phone') }}</b-td>
                <b-td>
                    <a target="_blank" :href="'tel:'+billingAddress.telephone"
                    >{{ billingAddress.telephone }}</a>
                </b-td>
                <b-td>
                    <a target="_blank" :href="'tel:'+shippingAddress.telephone"
                    >{{ shippingAddress.telephone }}</a>
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.mobile') }}</b-td>
                <b-td>
                    <a target="_blank" :href="'tel:'+billingAddress.mobile"
                    >{{ billingAddress.mobile }}</a>
                </b-td>
                <b-td>
                    <a target="_blank" :href="'tel:'+shippingAddress.mobile"
                    >{{ shippingAddress.mobile }}</a>
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>{{ $t('offering.address.email') }}</b-td>
                <b-td>{{ billingAddress.email }}</b-td>
                <b-td>{{ shippingAddress.email }}</b-td>
            </b-tr>
            </tbody>
        </b-table-simple>

        <b-row>
            <b-col cols="12">
                <b-card
                    border-variant="danger"
                    align="center"
                >
                    <b-card-text>
                        <b-row>
                            <b-col class="text-left" cols="12">{{ $t('offering.address.change_customer') }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-left">
                                <b-button
                                    @click="$root.$children[0].openModal('offering-change-customer', {offeringId:offeringId}, afterUpdate, {width: '800px'})"
                                    variant="danger"
                                    class="mb-2 mt-2"
                                >
                                    <font-awesome-icon icon="exchange-alt"/>
                                    {{ $t('common.toasts.change') }}
                                </b-button>

                                <b-button
                                    @click="createNewCustomer"
                                    variant="danger"
                                    class="mb-2 mt-2 ml-2"
                                >
                                    <font-awesome-icon icon="plus"/>
                                    {{ $t('common.toasts.create_from_address') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'OfferingAddress',
    props: {
        'offeringId': {
            type: [Number],
            required: true
        },
        'shippingAddress': {
            type: [Object],
            required: true
        },
        'billingAddress': {
            type: [Object],
            required: true
        },
        'vatCheckSettings': {
            type: [Object],
            required: true
        },
        'vatRequest': {
            type: [Object, null],
            required: true
        },
        'providerId': {
            type: [Number],
        },
        'afterUpdate': {
            type: [Function],
        }
    },
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Offering', ['getVatRequest']),
        ...mapGetters('Offering', ['getAddress']),
        shown() {
            this.loading = false
        },
        checkVat() {
            const checkVat = this.$store.dispatch('Offering/recheckOfferingVatId', this.offeringId)
            Promise.all([checkVat])
                .then(() => {
                    let data = this.getVatRequest();
                    this.vatRequest.status = data.ErrorCode
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        syncAddress(type, id) {
            this.$store.dispatch('Offering/fetchSyncAddress', {
                id: id,
                type: type
            }).then(response => {
                this.afterUpdate()
            })
        },
        syncProviderAddress(type, id) {
            this.$store.dispatch('Offering/fetchSyncProviderAddress', {
                id: id,
                type: type
            }).then(response => {
                this.afterUpdate()
            })
        },
        copyAddress(type, id) {
            this.$store.dispatch('Offering/fetchCopyAddress', {
                id: id,
                type: type
            }).then(response => {
                this.afterUpdate()
            })
        },
        createNewCustomer() {
            this.$confirm(
                {
                    message: this.$t('common.confirmations.change'),
                    button: {
                        no: this.$t('common.button.no'),
                        yes: this.$t('common.button.yes')
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$store.dispatch('Offering/fetchCreateNewCustomer', {
                                id: this.offeringId,
                            }).then(response => {
                                this.afterUpdate()
                            })
                        }
                    }
                }
            )
        }
    },
}
</script>