<template>
    <div>
        <zw-table ref="table"
                  tableName="mails"
                  columnsPrefix="mail.column."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :selectedSource="selectedSource"
                  :tbody-tr-class="rowClass"
                  title="mail.title.mails"
                  :sticky="false"
                  sticky-header="100%"
                  :actions-list="getActions()"
        >

            <template #cell(customer_id)="row">
                <a href="#"
                   v-if="row.item.customer_id"
                   @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.customer_id.id}, refreshTable)">
                    {{ row.item.customer_id.agenturname }} {{ row.item.customer_id.contact_person.vorname }}
                    {{ row.item.customer_id.contact_person.name }}
                </a>
            </template>
            <template #cell(offering_id)="row">
                <a href="#"
                   v-if="row.item.offering_id"
                   @click.prevent="$root.$children[0].openModal('offering-modal', {id:row.item.offering_id.id}, refreshTable)">
                    {{ row.item.offering_id.number }}
                </a>
            </template>
            <template #cell(attachments)="row">
                <div v-for="(atttachment,index) in row.item.attachments" :key="index">
                    <a
                        href="#"
                        @click.prevent="openAttachment(atttachment)">
                        <template v-if="atttachment.name">{{ atttachment.name.replace(/^.*[\\\/]/, '') }}</template>
                        <template v-else>{{ atttachment }}</template>
                    </a>
                </div>
            </template>

            <template #cell(opened_at)="row">
                <div v-if="row.item.opened_at">{{
                        row.item.opened_at | formatDate('DD.MM.YYYY HH:mm:ss')
                    }}
                </div>
                <div v-else>-</div>
            </template>

            <template #cell(clicked_at)="row">
                <div v-if="row.item.clicked_at">{{
                        row.item.clicked_at | formatDate('DD.MM.YYYY HH:mm:ss')
                    }}
                </div>
                <div v-else>-</div>
            </template>

            <template #cell(actions)="row">
                <b-dropdown class="mb-1"
                            left
                            variant="primary"
                            size="sm"
                >
                    <template #button-content>
                        {{ $t('common.label.actions') }}
                    </template>

                    <b-dropdown-item
                        @click="$root.$children[0].openModal('show-email-message-modal', {messageId: row.item.message_id}, null, {width: '800px'})">
                        <font-awesome-icon class="mr-2" icon="edit"/>
                        {{ $t('common.title.edit') }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Mails',
    mixins: [commonTable],
    props: {
        customerId: {
            type: Number,
            required: true
        },
        offeringId: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('Mail', ['getMailsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Mail/fetchMails', 'getMailsTable');
        },
        ctxAdditionalParams() {
            return this.offeringId != 0
                ? {offering_id: this.offeringId, customer_id: this.customerId}
                : {customer_id: this.customerId};
        },
        rowClass(item, type) {
            let rowClass = item ? (item.opened_at ? 'table-success' : 'table-danger') : '';
            return rowClass;
        },
        getActions() {
            const actions = [{
                title: this.$t('common.button.send_mail'),
                icon: 'envelope',
                click: () => {
                    const documentId = this.offeringId != 0 ? this.offeringId : this.customerId;
                    const docType = this.offeringId != 0 ? 'offering_email' : 'customer_email';
                    this.$root.$children[0].openModal('email-modal', {documentId, docType}, this.shown, {});
                },
            }];

            return actions;
        },
        openAttachment(attachment) {
            if(attachment.type) {
                if (attachment.type == 'attachment' || attachment.type == 'offering_attachment') {
                    this.$root.openDocument(attachment.id, 'media')
                } else if (attachment.type == 'contract') {
                    this.$root.openDocument(attachment.name, 'customer_contracts')
                } else if (attachment.type == 'simple') {
                    this.$root.openDocument(attachment.name, 'simple')
                } else {
                    this.$root.openDocument(attachment.name, 'invoices')
                }
            } else {
                this.$root.openDocument(encodeURIComponent(attachment), 'absolute')
            }
        }
    },
}
</script>